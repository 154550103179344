import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import AuthService from '@/services/AuthService'

const DefaultContainer = () => import('@/views/base/DefaultContainer')

const requireAuth = () => async (from, to, next) => {
  if (store.getters.getIsLogin)
    return next()

  const response = await AuthService.getUser(Vue.cookie.get('londonsms_token'))
  if (response.status === 200) {
    return next()
  }
  else {
    return next('/')
  }
}

const requireAdmin = () => async (from, to, next) => {
  if (store.getters.getIsAdmin)
    return next()

  const response = await AuthService.getUser(Vue.cookie.get('londonsms_token'))
  if (response.status === 200) {
    const { user } = response.data
    return user.level === 9 ? next() : next('/')
  }
  else {
    return next('/')
  }
}

// Views - Pages
const Login = () => import('@/views/pages/Login')
const Logout = () => import('@/views/pages/Logout')
const Register = () => import('@/views/pages/Register')
const Board = () => import('@/views/pages/Board')
const Mypage = () => import('@/views/pages/Mypage')

const Home = () => import('@/views/pages/Home')
const SmsSend = () => import('@/views/pages/SmsSend')
const SmsSendTest = () => import('@/views/pages/SmsSendTest')
const Contact = () => import('@/views/pages/Contact')
const Contacts = () => import('@/views/pages/Contacts')
const SmsRequests = () => import('@/views/pages/SmsRequests')
const SmsRequest = () => import('@/views/pages/SmsRequest')
const Dumps = () => import('@/views/pages/Dumps')

const DepositStep1 = () => import('@/views/pages/DepositStep1')
const DepositStep2 = () => import('@/views/pages/DepositStep2')
const DepositHistory = () => import('@/views/pages/DepositHistory')
const Payment = () => import('@/views/pages/Payment')
const PaymentHistory = () => import('@/views/pages/PaymentHistory')
const PaymentVBank = () => import('@/views/pages/PaymentVBank')

// Admin
const Users = () => import('@/views/admin/Users')
const User = () => import('@/views/admin/User')
const Distributor = () => import('@/views/admin/Distributor')
const DepositRequests = () => import('@/views/admin/DepositRequests')
const RecommendCodes = () => import('@/views/admin/RecommendCodes')
const BanList = () => import('@/views/admin/BanList')
const HealthCheck = () => import('@/views/admin/HealthCheck')
const Statics = () => import('@/views/admin/Statics')

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({
    y: 0,
  }),
  beforeRouteUpdate(to, from, next) {
    next() // IMPORTANT: *after* next() else the ?refresh sticks around half the time
    this.checkRefresh()
  },
  created() {
    this.checkRefresh()
  },
  methods: {
    checkRefresh() {
      console.log('check refresh')
      // ?refresh to force refresh if clicking eg the icon/button in the header menu
      // we make sure it doesn't stick around in the url bar for the user
      if (this.$route.query.refresh !== undefined) {
        this.loaded = false
        this.$router.replace(this.$route.path)
      } else {
        this.fetchData()
      }
    },
    fetchData() {
      // ... anything you want to happen on create or update
    },
  },
  routes: [
    {
      path: '/',
      name: 'root',
      component: DefaultContainer,
      children: [
        {
          path: '',
          name: 'Home',
          component: Home,
        },
        {
          path: 'smsSend',
          name: 'SmsSend',
          component: SmsSend,
          beforeEnter: requireAuth(),
        },
        {
          path: 'smsSendTest',
          name: 'SmsSendTest',
          component: SmsSendTest,
          beforeEnter: requireAuth(),
        },
        {
          path: 'contacts',
          name: 'Contacts',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          beforeEnter: requireAuth(),
          children: [
            {
              path: '',
              name: 'Contacts',
              component: Contacts,
              beforeEnter: requireAuth(),
            },
            {
              path: ':id',
              name: 'Contact',
              component: Contact,
              beforeEnter: requireAuth(),
            },
          ],
        },
        {
          path: 'contact',
          name: 'Contact',
          component: Contact,
          beforeEnter: requireAuth(),
        },
        {
          path: 'smsRequests',
          name: 'SmsRequests',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'SmsRequests',
              component: SmsRequests,
              beforeEnter: requireAuth(),
            },
            {
              path: ':id',
              name: 'SmsRequest',
              component: SmsRequest,
              beforeEnter: requireAuth(),
            },
          ],
        },
        {
          path: 'dumps',
          name: 'Dumps',
          component: Dumps,
          beforeEnter: requireAuth(),
        },
        {
          path: 'login',
          name: 'Login',
          component: Login,
        },
        {
          path: 'logout',
          name: 'Logout',
          component: Logout,
        },
        {
          path: 'register',
          name: 'Register',
          component: Register,
        },
        {
          path: 'board',
          name: 'Board',
          component: Board,
        },
        {
          path: 'mypage',
          name: 'Mypage',
          component: Mypage,
        },
        {
          path: '/payment',
          name: 'payment',
          component: Payment,
        },
        {
          path: '/payment',
          name: '',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'Payment',
              component: Payment,
              beforeEnter: requireAuth(),
            },
            {
              path: 'history',
              name: 'PaymentHistory',
              component: PaymentHistory,
              beforeEnter: requireAuth(),
            },
            {
              path: 'vbank/:id',
              name: 'PaymentVBank',
              component: PaymentVBank,
              beforeEnter: requireAuth(),
            },
          ],
        },
        {
          path: '/deposit',
          name: 'Deposit',
          redirect: '/deposit/step1',
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: 'step1',
              name: 'DepositStep1',
              component: DepositStep1,
              beforeEnter: requireAuth(),
            },
            {
              path: 'step2',
              name: 'DepositStep2',
              component: DepositStep2,
              beforeEnter: requireAuth(),
            },
            {
              path: 'history',
              name: 'DepositHistory',
              component: DepositHistory,
              beforeEnter: requireAuth(),
            },
          ],
        },
      ],
    },
    {
      path: '/admin',
      redirect: '/admin/depostRequests',
      component: DefaultContainer,
      children: [
        {
          path: 'healthCheck',
          name: 'HealthCheck',
          component: HealthCheck,
          beforeEnter: requireAdmin(),
        },
        {
          path: 'depostRequests',
          name: 'DepostRequests',
          component: DepositRequests,
          beforeEnter: requireAdmin(),
        },
        {
          path: 'recommendCodes',
          name: 'RecommendCodes',
          component: RecommendCodes,
          beforeEnter: requireAdmin(),
        },
        {
          path: 'banList',
          name: 'BanList',
          component: BanList,
          beforeEnter: requireAdmin(),
        },
        {
          path: 'statics',
          name: 'Statics',
          component: Statics,
          beforeEnter: requireAdmin(),
        },
        {
          path: 'users',
          beforeEnter: requireAdmin(),
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users,
              beforeEnter: requireAdmin(),
            },
            {
              path: ':id',
              name: 'User',
              component: User,
            },
          ],
        },
        {
          path: 'distributors',
          name: 'Distributors',
          component: Distributor,
        },
      ],
    },
    {
      path: '*',
      redirect: '/'
    },
  ],
})

<template>
  <div>
    <router-view></router-view>
    <div id="toast-container" class="toast-top-right">
      <div class="toast toast-danger" v-for="note in notes" :key="note.id">
        <button type="button" class="toast-close-button" role="button" @click="readNote(note.id)">×</button>
        <div class="toast-message">{{ note.body }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService'
import NoteService from '@/services/NoteService'

export default {
  name: 'app',
  data() {
    return {
      notes: [],
    }
  },

  watch: {
    '$store.state.status'() {
      this.notes = this.$store.state.status.notes
    },
  },
  methods: {
    async readNote(id) {
      try {
        await NoteService.update(id, { isRead: 1 })
        this.getStatus()
      } catch (e) {
        // Do nothing
      }
    },
  },
}
</script>

<style lang="scss">
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.min.css';
/* Import Font Awesome Icons Set */

$fa-font-path: '~font-awesome/fonts/';
@import '~font-awesome/scss/font-awesome.scss';
/* Import Simple Line Icons Set */

$simple-line-font-path: '~simple-line-icons/fonts/';
@import '~simple-line-icons/scss/simple-line-icons.scss';
/* Import Flag Icons Set */

@import '~flag-icon-css/css/flag-icon.min.css';
/* Import Bootstrap Vue Styles */

@import '~bootstrap-vue/dist/bootstrap-vue.css'; // Import Main styles for this application
@import 'assets/scss/style';

#toast-container {
  position: fixed;
  top: 92px;
  right: 12px;
  z-index: 999999;

  .toast {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px;
    width: 300px;
    border-radius: 0.25rem;
    background-position: 15px center;
    background-repeat: no-repeat;
    color: #333;
    opacity: 0.9;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
    filter: alpha(opacity=90);
    background-color: #ffe572;

    .toast-close-button {
      padding: 0;
      cursor: pointer;
      background: transparent;
      border: 0;
      -webkit-appearance: none;
      position: relative;
      right: -0.3em;
      top: -0.3em;
      float: right;
      font-size: 20px;
      font-weight: bold;
      color: #333;
      -webkit-text-shadow: 0 1px 0 #333;
      text-shadow: 0 1px 0 #333;
      opacity: 0.8;
      -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
      filter: alpha(opacity=80);
      cursor: pointer;
    }
  }
}
</style>

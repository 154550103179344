import Api from '@/services/Api'

export default {
  create(params) {
    return Api().post('/api/notes', params)
  },
  update(id, params) {
    return Api().put('/api/notes/' + id, params)
  },
}

import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from '@/router'
import store from '@/store'
import moment from 'moment-timezone'
import DOMPurify from 'dompurify';
import 'vue-datetime/dist/vue-datetime.css'
import { sync } from 'vuex-router-sync'
import VueHead from 'vue-head'

Vue.use(BootstrapVue)
Vue.use(VueCookie)
Vue.use(VueHead)

Vue.filter('timeFormat', function (value) {
  let retVal = ''
  if (value) {
    const min = parseInt(value / 60)
    const sec = value - min * 60
    retVal = min + ':'

    if (sec < 10) {
      retVal += '0'
    }
    retVal += sec
  }
  return retVal
})

Vue.filter('dateFormat', function (value) {
  if (value) {
    return moment(String(value))
      .tz('Asia/Seoul')
      .format('YYYY.MM.DD LTS')
  }
})

Vue.filter('dateFormat2', function (value) {
  if (value) {
    return moment(String(value))
      .tz('Asia/Seoul')
      .format('YYYY.MM.DD HH:mm')
  }
})

Vue.filter('dateShortFormat', function (value) {
  if (value) {
    return moment(String(value))
      .tz('Asia/Seoul')
      .format('YYYY.MM.DD')
  }
})

Vue.filter('dateFormatWithNewLine', function (value) {
  if (value) {
    return moment(String(value))
      .tz('Asia/Seoul')
      .format('YYYY.MM.DD[\r\n]LTS')
  }
})

Vue.filter('phoneFormat', function (value) {
  value = String(value)
  if (!value) {
    return ''
  }

  value = value.replace(/-/g, '')
  if (value.startsWith('82')) {
    value = '0' + value.substring(2)
  }

  if (value.length == 10) {
    value = value.substring(0, 3) + '-' + value.substring(3, 6) + '-' + value.substring(6, 10)
  } else if (value.length > 10) {
    value = value.substring(0, 3) + '-' + value.substring(3, 7) + '-' + value.substring(7)
  }
  return value
})

Vue.filter('numberFormat', function (value) {
  if (!value) return '0'
  var regexp = /\B(?=(\d{3})+(?!\d))/g
  return value.toString().replace(regexp, ',')
})
import AuthService from '@/services/AuthService'
import StatusService from '@/services/StatusService'

Vue.mixin({
  mounted() {
    this.checkChatBot(true)
  },
  methods: {
    async getUserInfo() {
      try {
        const response = await AuthService.getUser(this.$cookie.get('londonsms_token'))
        const { user } = response.data

        if (!this.$store.state.user && user) {
          window.dataLayer.push({
            'user_id': user.chatKey,
          });
        }

        await this.$store.dispatch('setUser', user)

        this.checkChatBot(user)
      } catch (err) {
        this.checkChatBot()
      }
    },
    async checkChatBot(user) {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      if (isMobile) {
        return
      }
      clearTimeout(window.chatTimer)
      window.chatTimer = setTimeout(() => {
        window.ChannelIO('shutdown')

        let settings = null

        if (user) {
          settings = {
            pluginKey: 'acd89c0e-3b11-4dfb-9623-35549aed2701',
            memberHash: user.chatKey, //fill with user id
            memberId: user.email,
            profile: {
              code: user.recommendCode,
              name: user.username,
              email: user.email,
            },
          }
        } else {
          settings = {
            pluginKey: 'acd89c0e-3b11-4dfb-9623-35549aed2701',
          }
        }
        window.ChannelIO('boot', settings)
      }, 200)
    },
    async getStatus() {
      try {
        const response = await StatusService.getStatus()
        this.$store.dispatch('setStatus', response.data)
      } catch (e) {
        // Do nothing
      }
    },
    showSpinner() {
      clearTimeout(this.spinnerTimer)
      const vm = this
      this.spinnerTimer = setTimeout(function () {
        vm.$store.dispatch('showSpinner', true)
      }, 400)
    },
    hideSpinner() {
      clearTimeout(this.spinnerTimer)
      const vm = this
      this.spinnerTimer = setTimeout(function () {
        vm.$store.dispatch('showSpinner', false)
      }, 400)
    },
    async logout() {
      await AuthService.logout();
      this.$cookie.delete('londonsms_token')
      this.$store.dispatch('setToken', null)
      this.$store.dispatch('setUser', null)
      location.href = '/'
    },
    domPurify: function (html) {
      return DOMPurify.sanitize(html);
    },
  },
})

sync(store, router)

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/errors/404')
  } else {
    next()
  }
})

/* eslint-disable no-new */
var app = new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App,
  },
})

window.app = app

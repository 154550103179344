import Api from '@/services/Api'

export default {
  getStatus() {
    return Api(undefined, { hideAlert: true }).get('/api/status')
  },
  getSetting() {
    return Api(undefined, { hideAlert: true }).get('/api/status/setting')
  },
  updateSetting(testProviderId, testErrMsg, testRecipients, testMemo) {
    return Api().put('/api/status/setting', { testProviderId, testErrMsg, testRecipients, testMemo })
  },
  healthCheck() {
    return Api().get('/api/status/healthCheck')
  },
}

import axios from 'axios'

export default (token, option = {}) => {
  var data = {
    headers: {},
  }

  if (!token && window.app.$cookie) {
    token = window.app.$cookie.get('londonsms_token')
  }

  if (token) {
    data.headers.Authorization = 'bearer ' + token
  }
  var instance = axios.create(data)

  instance.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response
    },
    function (error) {
      // Do something with response error
      if (error.response) {
        var status = error.response.status
        var data = error.response.data
        if (status === 401) {
          window.app.logout()
        } else {
          var err = data.error
          if (!err) {
            if (status == 401) {
              err = '잘못된 파라미터입니다'
            } else if (status == 500) {
              err = '내부 서버 에러'
            } else {
              err = '에러[' + status + ']';

              if (data.response) {
                err += ' - ' + data.response
              }
            }
          }

          error = err

          if (!option.hideAlert) {
            alert(err)
          }
        }
      }
      return Promise.reject(error)
    }
  )
  return instance
}
